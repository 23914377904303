exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-abmahnungen-aufgrund-der-externen-einbundung-von-google-fonts-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/abmahnungen-aufgrund-der-externen-einbundung-von-google-fonts.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-abmahnungen-aufgrund-der-externen-einbundung-von-google-fonts-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-einrichten-einer-magento-2-entwicklungsumgebung-mit-ddev-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/einrichten-einer-magento2-entwicklungsumgebung-mit-ddev.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-einrichten-einer-magento-2-entwicklungsumgebung-mit-ddev-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-magento-2-sterne-fuer-produkte-ohne-bewertungen-in-kategorieansicht-anzeigen-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/magento2-sterne-fuer-produkte-ohne-bewertungen-in-kategorieansicht-anzeigen.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-magento-2-sterne-fuer-produkte-ohne-bewertungen-in-kategorieansicht-anzeigen-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-sendcloud-tracking-link-in-shopware-6-versandbestaetigungen-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/sendcloud-tracking-link-in-shopware-6-versandbestaetigungen.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-sendcloud-tracking-link-in-shopware-6-versandbestaetigungen-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-shopware-6-eigene-font-am-beispiel-open-sans-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/shopware-6-eigene-font-am-beispiel-open-sans.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-shopware-6-eigene-font-am-beispiel-open-sans-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-shopware-6-systemd-services-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/shopware-6-systemd-services.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-shopware-6-systemd-services-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-typo-3-composer-mode-autoloading-bei-lokalen-extensions-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/typo3-composer-mode-autoloading-bei-lokalen-extensions.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-typo-3-composer-mode-autoloading-bei-lokalen-extensions-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-blog-wordpress-ueber-die-shell-mittels-wp-cli-installieren-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/schnere/Projekte/schneidewind.it/blog/wordpress-ueber-die-shell-mittels-wp-cli-installieren.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-blog-wordpress-ueber-die-shell-mittels-wp-cli-installieren-mdx" */)
}

